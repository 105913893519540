/* eslint-disable max-classes-per-file */
import deepmerge from 'deepmerge';
import { Chart } from 'chart.js';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
} from '@angular/core';

import type { Lead, HomeValueChartConfig, HomeValueChartArguments, LeadFinancingHistories } from '@core/types';

// Consts
import { CHART_CONFIG } from '@consts/diagrams/home-value';

// Services
import { HelpersService } from '@core/helpers/helpers.service';

@Component({
  selector: 'home-value-chart',
  templateUrl: './home-value-chart.component.html',
  styleUrls: ['./home-value-chart.component.sass'],
})
export class HomeValueChartComponent implements OnInit, OnDestroy {

  @ViewChild('homeValueChart', { static: true }) private chartRef: any;

  @Input() lead: Lead;

  @Input() valueForecast: number;

  chart: any;

  chartConfig: HomeValueChartConfig = CHART_CONFIG;

  constructor(
    private helpersService: HelpersService,
  ) {}

  ngOnInit(): void {
    this.initDiagram();
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  private identifyGradientDirection(lead_financing_histories: LeadFinancingHistories[]): string {
    if (lead_financing_histories.every((value: LeadFinancingHistories) => value.gross_equity >= 0)) { return 'positive'; }

    if (lead_financing_histories.every((value: LeadFinancingHistories) => value.gross_equity < 0)) { return 'negative'; }

    return 'mixed';
  }

  private getGradientStyle(direction: string): any {
    const context: any = this.chartRef.nativeElement.getContext('2d');
    const gradient: any = context.createLinearGradient(0, 0, 0, 120);

    switch (direction) {
      case 'mixed': {
        gradient.addColorStop(0, 'rgba(0, 137, 255, 0.8)');
        gradient.addColorStop(0.4, 'rgba(0, 137, 255, 0.8)');
        gradient.addColorStop(0.5, 'rgba(0, 137, 255, 0.4)');
        gradient.addColorStop(0.7, 'rgba(0, 137, 255, 0)');
        gradient.addColorStop(0.8, 'rgba(0, 137, 255, 0.2)');
        gradient.addColorStop(1, 'rgba(0, 137, 255, 0.8)');
        break;
      }
      case 'positive': {
        gradient.addColorStop(0, 'rgba(0, 137, 255, 0.8)');
        gradient.addColorStop(0.3, 'rgba(0, 137, 255, 0.5)');
        gradient.addColorStop(0.5, 'rgba(0, 137, 255, 0.3)');
        gradient.addColorStop(1, 'rgba(0, 137, 255, 0)');
        break;
      }
      case 'negative': {
        gradient.addColorStop(0, 'rgba(0, 137, 255, 0)');
        gradient.addColorStop(0.3, 'rgba(0, 137, 255, 0)');
        gradient.addColorStop(0.5, 'rgba(0, 137, 255, 0.1)');
        gradient.addColorStop(0.7, 'rgba(0, 137, 255, 0.3)');
        gradient.addColorStop(1, 'rgba(0, 137, 255, 0.6)');
        break;
      }
      default: {
        break;
      }
    }

    return gradient;
  }

  initDiagram(): void {
    const direction: string = this.identifyGradientDirection(this.lead.lead_financing_histories);
    const gradient: string = this.getGradientStyle(direction);
    const payload: HomeValueChartArguments = {
      data: this.lead.lead_financing_histories,
      valueForecast: this.lead.gross_equity_forecast,
      lineColor: '#0089ff',
      dashLineColor: '#b8cbd9',
    };
    const combineMerge = (target: any, source: any, options: any): any => {
      const destination: any = target.slice();

      source.forEach((item: any, index: any) => {
        if (typeof destination[index] === 'undefined') {
          destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
        } else if (options.isMergeableObject(item)) {
          destination[index] = deepmerge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });

      return destination;
    };

    this.chartConfig = deepmerge.all<HomeValueChartConfig>(
      [
        this.chartConfig,
        {
          options: {
            tooltips: { backgroundColor: '#0089ff' },
            scales: {
              yAxes: [{ gridLines: { color: '#eff4f7' } }],
              xAxes: [{ ticks: { fontFamily: 'Mukta' } }],
            },
            maintainAspectRatio: false,
          },
          data: this.helpersService.getLeadFinancingHistoriesDataset(payload),
        },
        {
          data: {
            datasets: [
              {
                fill: true,
                pointBackgroundColor: '#fff',
              },
            ],
          },
        },
      ],
      { arrayMerge: combineMerge },
    );

    this.chartConfig.data.datasets[0].backgroundColor = gradient;

    this.chart = new Chart(this.chartRef.nativeElement, this.chartConfig);
  }

}
