import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleModalModule } from 'ngx-simple-modal';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ToastrModule } from 'ngx-toastr';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { CurrencyPipe, DecimalPipe, CommonModule } from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AsapCommonModule } from 'asap-team/asap-tools';

// Directives
import { FeatureFlagDirective } from '@commons/directives/feature-flags/feature-flag.directive';

// Components
import { AvatarPlaceholderComponent } from './avatar-placeholder/avatar-placeholder.component';
import { LoanItemComponent } from './loan-item/loan-item.component';
import { VerifyHomeValueComponent } from './verify-home-value/verify-home-value.component';
import { IqButtonComponent } from './iq-button/iq-button.component';
import { FormErrorComponent } from './form-error/form-error.component';

// Dialogs
import { ChangeEmailDialogComponent } from './dialogs/change-email-dialog/change-email-dialog.component';
import { MortgageHelpDialogComponent } from './dialogs/mortgage-help-dialog/mortgage-help-dialog.component';
import { PmiHelpDialogComponent } from './dialogs/mortgage-help-dialog/pmi-help-dialog/pmi-help-dialog.component';
import { ConventionalPmiHelpDialogComponent } from './dialogs/mortgage-help-dialog/conventional-pmi-help-dialog/conventional-pmi-help-dialog.component';
import { LtvHelpDialogComponent } from './dialogs/mortgage-help-dialog/ltv-help-dialog/ltv-help-dialog.component';

const imports: any = [
  CommonModule,
  Angular2PromiseButtonModule.forRoot({ btnLoadingClass: 'busy' }),
  AngularSvgIconModule.forRoot(),
  FormsModule,
  InlineSVGModule.forRoot({ bypassHttpClientInterceptorChain: true }),
  NgxMaskModule.forRoot(),
  ReactiveFormsModule,
  RxReactiveFormsModule,
  SimpleModalModule,
  ToastrModule.forRoot({
    autoDismiss: true,
    maxOpened: 1,
    positionClass: 'toast-top-center',
    preventDuplicates: true,
    toastClass: 'notify',
  }),
  TooltipModule,
  AsapCommonModule,
];

const exported: any = [
  CommonModule,
  Angular2PromiseButtonModule,
  AngularSvgIconModule,
  FormsModule,
  NgxMaskModule,
  ReactiveFormsModule,
  RxReactiveFormsModule,
  SimpleModalModule,
  ToastrModule,
  TooltipModule,
  AsapCommonModule,
  InlineSVGModule,
];

const dialogs: any = [
  ChangeEmailDialogComponent,
  MortgageHelpDialogComponent,
  PmiHelpDialogComponent,
  ConventionalPmiHelpDialogComponent,
  LtvHelpDialogComponent,
];

const directives: any = [
  FeatureFlagDirective,
];

const shared: any = [
  FormErrorComponent,
  AvatarPlaceholderComponent,
  LoanItemComponent,
  VerifyHomeValueComponent,
  IqButtonComponent,
];

const pipes: any = [
  CurrencyPipe,
  DecimalPipe,
];

@NgModule({
  imports,
  exports: [
    ...exported,
    ...dialogs,
    ...directives,
    ...shared,
  ],
  declarations: [
    ...dialogs,
    ...shared,
    ...directives,
  ],
  providers: [
    ...pipes,
  ],
})
export class CommonsModule {}
