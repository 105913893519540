/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import { Chart } from 'chart.js';

import type { HomeValueChartConfig } from '@core/types';

Chart.defaults.multicolorLine = Chart.defaults.line;
Chart.controllers.multicolorLine = Chart.controllers.line.extend({
  draw(ease: any) {
    let startIndex: number = 0;
    const meta: any = this.getMeta();
    const points: any[] = meta.data || [];
    const { colors } = this.getDataset();
    const area: any = this.chart.chartArea;
    const originalDatasets: any = meta.dataset._children.filter((data: any) => !Number.isNaN(Number(data._view.y)));

    function setColor(newColor: string): void {
      meta.dataset._view.borderColor = newColor;
    }

    if (!colors) {
      Chart.controllers.line.prototype.draw.call(this, ease);

      return;
    }

    for (let i: number = 2; i <= colors.length; i++) {
      if (colors[i - 1] !== colors[i]) {
        setColor(colors[i - 1]);
        meta.dataset._children = originalDatasets.slice(startIndex, i);
        meta.dataset.draw();
        startIndex = i - 1;
      }
    }

    meta.dataset._children = originalDatasets.slice(startIndex);
    meta.dataset.draw();
    meta.dataset._children = originalDatasets;

    points.forEach((point: any) => {
      point.draw(area);
    });
  },
});

export const CHART_CONFIG: HomeValueChartConfig = {
  type: 'multicolorLine',
  data: {},
  options: {
    tooltips: {
      mode: 'single',
      displayColors: false,
      yAlign: 'bottom',
      backgroundColor: null,
      cornerRadius: 12,
      callbacks: {
        labelColor: (): boolean => false,
        label: (tooltipItem: any, data: any): string => {
          const datasetLabel: number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          return `$${Math.round(datasetLabel / 1000)}k`;
        },
        title: (): boolean => false,
      },
    },
    legend: { display: false },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false,
          display: false,
        },
        gridLines: {
          display: true,
          drawBorder: false,
          color: '#eff4f7',
          zeroLineColor: '#eff4f7',
        },
      }],
      xAxes: [{
        ticks: {
          fontSize: 13,
          fontFamily: 'Mukta',
          fontColor: 'rgba(64, 96, 128, 0.5)',
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      }],
    },
    elements: {
      line: {
        fill: false,
        borderWidth: 3,
        tension: 0.5,
      },
      point: { backgroundColor: '#fff' },
    },
    layout: {
      padding: {
        top: 30,
        left: 10,
        right: 10,
      },
    },
    maintainAspectRatio: false,
  },
};
