<div class="card send-report">
  <h2 class="send-report__title">Activate this report now. It’s Free!</h2>
  <p class="send-report__text">Your client will receive a monthly <i>my</i>homeIQ report with updated home value and
    refinance options. You will be branded as the Agent on each report once you activate your account.</p>
  <a class="pseudo-link send-report__link"
     *ngIf="isUserActivate"
     [href]="link"
     target="_blank"
     (click)="track()">Activate report</a>
  <a class="pseudo-link send-report__link"
     *ngIf="!isUserActivate"
     (click)="redirect()">Send this report to client</a>
  <div class="send-report__pin">
    <div class="icon" [inlineSVG]="'assets/icons/pin.svg'"></div>
  </div>
</div>
