import {
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

import type { Lead, HomeValueInfo } from '@core/types';

// Const
import { PREPARED_QUESTIONS, GoogleMapsAPILoaderConfig } from '@consts/index';

// Utils
import { getCorrectAddress } from '@core/utils/utils';

// Services
import { HelpersService } from '@core/helpers/helpers.service';

type PreviewEquityTrackerState = 'image' | 'diagram';

@Component({
  selector: 'equity-tracker',
  templateUrl: './equity-tracker.component.html',
  styleUrls: ['./equity-tracker.component.sass'],
})
export class EquityTrackerComponent implements OnInit, OnChanges {

  @ViewChildren('chartContainer', { read: ViewContainerRef, emitDistinctChangesOnly: true }) private chartContainer: QueryList<ViewContainerRef>;

  @Input() lead: Lead;

  info: HomeValueInfo;

  previewState: PreviewEquityTrackerState;

  private mapsAPILoader: Loader = new Loader(GoogleMapsAPILoaderConfig);

  gmMapTypeId!: google.maps.MapTypeId;

  gmAPIReady: boolean = false;

  gmMarkerOptions: google.maps.MarkerOptions = { draggable: false };

  preparedQuestions: string[] = PREPARED_QUESTIONS.home_value;

  getCorrectAddress: (address: string) => string = getCorrectAddress;

  get isShowFinancingDiagram(): boolean {
    return !!this.lead.lead_financing_histories && !!this.lead.gross_equity_forecast;
  }

  constructor(
    private helpersService: HelpersService,
  ) {}

  ngOnInit(): void {
    this.previewState = this.isShowFinancingDiagram ? 'diagram' : 'image';

    this.mapsAPILoader.load().then((google: any) => {
      this.gmAPIReady = true;
      this.gmMapTypeId = google.maps.MapTypeId.SATELLITE;
    });
  }

  ngOnChanges(): void {
    this.info = this.helpersService.getHomeValueInfo(this.lead);
  }

  setPreviewState(state: PreviewEquityTrackerState): void {
    if (state === this.previewState) { return; }

    this.previewState = state;
  }

  checkPreviewState(state: PreviewEquityTrackerState): boolean {
    return this.previewState === state;
  }

  getTrendClass({ current_diff_valuation }: HomeValueInfo): { increase: boolean; decrease: boolean } {
    return {
      increase: current_diff_valuation > 0,
      decrease: current_diff_valuation < 0,
    };
  }

  isLess(value: number): boolean {
    return value < 0;
  }

}
