import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TrackingScriptsService {

  private readonly env: string = environment.analytics.env;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  /* eslint-disable */
  public setupGoogleTagManager(): void {
    const window = this.window as any;
    const document = this.document as any;
    const containerId = environment.analytics.gtm;

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      w.gtag = function() {
        w[l].push(arguments);
      }
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';

      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    }(window, document, 'script', 'dataLayer', containerId));

    if (this.env) {
      window.gtag('set', 'dimension3', this.env);
      window.dataLayer?.push({
        'event': 'setDimension',
        'environment': this.env
      });
    }
  }
  /* eslint-enable */

}
