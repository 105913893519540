<div class="loading-dots loading-dots--lg" *ngIf="isLoading else homePrice">
  <div class="loading-dots__item loading-dots__item--1"></div>
  <div class="loading-dots__item loading-dots__item--2"></div>
  <div class="loading-dots__item loading-dots__item--3"></div>
</div>
<ng-template #homePrice>
  <ng-container *ngIf="isShowHomePrice">
    <div class="buy-another-house__price">{{ buyAnotherHouseInfo?.home_price | amount }}</div>
    <div class="buy-another-house__text--small">
      Your estimated monthly payment will be {{ buyAnotherHouseInfo?.monthly_payment | amount }}
      <span class="refinance-estimator__rate--tooltip-info" [tooltip]="tooltip.DISCLAIMER_INFO" [options]="tooltipOptions">*</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!isShowHomePrice">
    <div class="buy-another-house__price">😐</div>
    <div class="buy-another-house__text">
      Unfortunately, based on your inputs, we are not able to provide you an instant pre-qualification amount.
    </div>
  </ng-container>
</ng-template>
<div class="sticky">
  <div class="sticky-element" #sticky></div>
  <div class="sticky-header" [class.show]="stickyHeader">
    <ng-container *ngIf="isShowHomePrice">
      <div class="sticky-header__info" *ngIf="ofType(BUY_ANOTHER_HOUSE.PRIMARY_HOME)">
        <div class="sticky-header__icon">
          <img src="/assets/components/buy-another-house/active-1.svg" />
        </div>
        <p class="sticky-header__title text">Trading up to a new home</p>
      </div>
      <div class="sticky-header__info" *ngIf="ofType(BUY_ANOTHER_HOUSE.RENTAL_PROPERTY)">
        <div class="sticky-header__icon">
          <img src="/assets/components/buy-another-house/active-2.svg" />
        </div>
        <p class="sticky-header__title text">Buying an investment property</p>
      </div>
      <div class="sticky-header__info" *ngIf="ofType(BUY_ANOTHER_HOUSE.SECONDARY_HOME)">
        <div class="sticky-header__icon">
          <img src="/assets/components/buy-another-house/active-3.svg" />
        </div>
        <p class="sticky-header__title text">Purchasing a second house</p>
      </div>
      <div class="sticky-header__price">
        <h5>{{ buyAnotherHouseInfo?.home_price | amount }}</h5>
        <div class="caption-text">{{ buyAnotherHouseInfo?.monthly_spendings | amount }} monthly</div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isShowHomePrice">
      <div class="sticky-header__info">
        <div class="sticky-header__icon sticky-header__icon--emoji">😐</div>
        <p class="sticky-header__title sticky-header__title--default text">
          Unfortunately, based on your inputs, we are not able to provide you an instant pre-qualification amount.
        </p>
      </div>
    </ng-container>
  </div>
  <form class="buy-another-house__calculations" [formGroup]="form">
    <div class="buy-another-house__calculations--title">How this is calculated</div>
    <div class="buy-another-house__calculations--text">
      This affordability calculator is provided by myhomeIQ for educational purposes only and is not intended to reflect any lender’s available credit products or terms.
    </div>
    <div class="buy-another-house__calculations--wrapper">
      <div class="buy-another-house__calculations--col">
        <p class="buy-another-house__calculations--subtitle">Annual income</p>
        <div class="buy-another-house__calculations-label-wrapper">
          <div class="buy-another-house__calculations-label" [ngStyle]="getLabelStyle(form.controls?.annual_income?.value, 'annual_income')">
            {{ getActualValue(form.controls?.annual_income?.value, 'annual_income') | formatNumber: '1' : true }}
          </div>
        </div>
        <div>
          <input class="buy-another-house__range--input"
                 type="range"
                 min="0"
                 max="46"
                 formControlName="annual_income"
                 (click)="sliderClicked()"
                 [ngStyle]="getLineStyle(form.controls?.annual_income?.value, 'annual_income')" />
        </div>
      </div>
      <div class="buy-another-house__calculations--col">
        <p class="buy-another-house__calculations--subtitle">Monthly debt payments</p>
        <div class="buy-another-house__calculations-label-wrapper">
          <div class="buy-another-house__calculations-label"
               [ngStyle]="getLabelStyle(form.controls?.monthly_spendings?.value, 'monthly_spendings')">
            {{ getActualValue(form.controls?.monthly_spendings?.value, 'monthly_spendings') | formatNumber: '1' : true }}
          </div>
        </div>
        <div>
          <input class="buy-another-house__range--input"
                 type="range"
                 min="0"
                 max="125"
                 formControlName="monthly_spendings"
                 (click)="sliderClicked()"
                 [ngStyle]="getLineStyle(form.controls?.monthly_spendings?.value, 'monthly_spendings')" />
        </div>
      </div>
    </div>
    <p class="buy-another-house__calculations--subtitle">Credit score</p>
    <div class="buy-another-house__score">
      <div class="buy-another-house__score--item"
           *ngFor="let score of scores"
           [ngClass]="getScoreStyle(score)"
           (click)="selectScore(score?.type)">
        <div class="buy-another-house__score-check-icon">
          <svg-icon src="assets/components/buy-another-house/check-icon.svg"></svg-icon>
        </div>
        <div class="buy-another-house__score--icon"></div>
        <div class="buy-another-house__score--text">
          <div class="buy-another-house__score--title">{{ score?.title }}</div>
          <div class="buy-another-house__score--value">{{ score?.value }}</div>
        </div>
      </div>
    </div>
    <div class="flex buy-another-house__downpayment">
      <div class="flex-1">
        <p class="buy-another-house__calculations--subtitle">Down payment</p>
        <downpayment-slider *ngIf="!isHideDownpaymentSlider" (click)="sliderClicked()" (downpaymentAmount)="selectDownpaymentAmount($event)"></downpayment-slider>
        <ng-container *ngIf="isHideDownpaymentSlider">
          <div class="buy-another-house__calculations-label-wrapper">
            <div class="buy-another-house__calculations-label"
                 [ngStyle]="getLabelStyle(form.controls?.downpayment_amount?.value, 'downpayment_amount')">
              {{ form.controls?.downpayment_amount?.value | formatNumber: '1' : true }}
            </div>
          </div>
          <input class="buy-another-house__range--input m-b-18"
                 type="range"
                 min="0"
                 max="100000"
                 formControlName="downpayment_amount"
                 (click)="sliderClicked()"
                 [ngStyle]="getLineStyle(form.controls?.downpayment_amount?.value, 'downpayment_amount')" />
          <p class="buy-another-house__range--input-description">You have no equity in your home yet.<br/>Downpayment amount is expected to be your own money</p>
        </ng-container>
      </div>
      <div class="p-l-24">
        <p class="buy-another-house__calculations--subtitle">Loan term</p>
        <div class="buy-another-house__calculations--loan-term">
          <div class="buy-another-house__calculations--loan-term--button" [class.active]="currentLoadTerm === '15'" (click)="selectLoanTerm('15')">15 yrs</div>
          <div class="buy-another-house__calculations--loan-term--button" [class.active]="currentLoadTerm === '30'" (click)="selectLoanTerm('30')">30 yrs</div>
        </div>
      </div>
    </div>
    <div *ngIf="lead.affordable_calculator_disclaimer_text">
      <div class="disclaimer" [innerHTML]="sanitizedText"></div>
    </div>
  </form>
</div>
