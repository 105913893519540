<p class="footer__copy" *ngIf="isShowCopy">
  &copy; {{ currentYear | date:'yyyy' }} <i>my</i>homeIQ. All rights reserved.
</p>
<ul class="footer__nav">
  <li class="footer__nav-item">
    <a class="footer__nav-link" [href]="privacyPolicy" target="_blank">Privacy Policy</a>
  </li>
  <li class="footer__nav-item">
    <a class="footer__nav-link" [href]="termsOfUse" target="_blank">Terms of Service</a>
  </li>
  <li class="footer__nav-item">
    <a class="footer__nav-link" href="https://www.nmlsconsumeraccess.org/" target="_blank">nmlsconsumeraccess.org</a>
  </li>
</ul>
