<div class="card" *ngIf="isShowBlock" @fadeOut>
  <div class="card__content">
    <div class="loans">
      <h2 class="m-b-16 text-center">Do you have primary loan?</h2>
      <p class="m-b-24 text-center">You have active loans but none are selected as a primary.<br/>At the moment
        <i>my</i>homeIQ tracks your mortgage activity only for primary loans. So please mark below if you have an active
        one:</p>
      <div class="m-b-8">
        <loan-item *ngFor="let loan of lead.transactions; let i = index"
                   [ngStyle]="getGradient(i)"
                   [lead]="lead"
                   [loan]="loan"
                   [isShowPrimaryBtn]="!primaryLoan"
                   (emitEdit)="editLoan($event)"></loan-item>
      </div>
      <button class="btn btn-block btn-lg" type="button" [promiseBtn]="action$" (click)="verifyLoans()">I have repaid my
        primary loan
      </button>
    </div>
  </div>
</div>
