import { FeatureFlagsConfig } from '@core/types/FeatureFlags';

export const FEATURE_FLAGS_CONFIG: FeatureFlagsConfig = {
  smsNotifications: {
    enabled_on: {
      development: false,
      staging: false,
      production: false,
    },
  },
};
